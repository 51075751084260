import { css } from '@emotion/react'
import { media } from '@dfds-ui/theme'
import { Headline } from '@dfds-ui/react-components'
import { track } from '@dfds-frontend/tracking'

import { PageLayout } from '../Layout'
import { ContentRows } from './../ContentRows'
import { usePageTrackingData } from '../../tracking'

const ErrorComponent = ({ hero, contentRows }) => {
  const { getPageTrackingData } = usePageTrackingData()
  // eslint-disable-next-line no-unused-vars
  const { bookingEngine, businessArea, currentLocale, ...rest } =
    getPageTrackingData()
  const trackingData = {
    ...rest,
    event: 'vpv',
    // some values are hardcoded here since they will not be set correctly if navigating to the page
    contentfulId: '4tbs2kmu5Pm7CbAIeWOtYN',
    bookingEngine: bookingEngine?.toLowerCase(),
    businessArea: businessArea?.toLowerCase(),
    pageTitle: '404',
    statusCode: '404',
    pagePath: typeof window !== 'undefined' && window.originalPath404,
  }

  track(trackingData)
  return (
    <PageLayout spaced={true}>
      <Headline
        as={'h1'}
        css={css`
          width: 100%;
          margin-left: 20px;
          margin-right: 20px;

          ${media.greaterThan('m')`
            margin-left: 0;
            margin-right: 0;
          `}
        `}
      >
        {hero.contentRef.text}
      </Headline>
      <ContentRows contentRows={contentRows} />
    </PageLayout>
  )
}

export default ErrorComponent
